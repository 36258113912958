import { isValid } from "iban";
import { z } from "zod";
import {
  countries,
  currencies,
  defaultCountry,
  defaultCurrency,
  defaultDocumentLocale,
  defaultTimezone,
  documentLocales,
  invoicingTypes,
  timezones,
} from "@evenli-platform/ui/lib/constants";
import { components, paths } from "@evenli-platform/core/clients/evenli/evenli";

export type Memberships =
  paths["/dashboard/v1/users/{userId}/memberships"]["get"]["responses"]["200"]["content"]["application/json"]["memberships"];

// Schema for SIGN IN form
export const signInSchema = z.object({
  email: z.string().email({ message: "enter_a_valid_email_address" }),
  password: z.string().min(8, "password_must_be_at_least_8_characters"),
});
export type SignInSchemaType = z.infer<typeof signInSchema>;

export const codeRequestFormSchema = z.object({
  email: z.string().email({ message: "enter_a_valid_email_address" }),
});
export type CodeRequestSchemaType = z.infer<typeof codeRequestFormSchema>;

export const newPasswordSchema = z
  .object({
    code: z.string().min(1, "this_field_is_required"),
    password: z
      .string()
      .min(8, "password_must_be_at_least_8_characters")
      .refine((value) => /[a-z]/.test(value), {
        message: "password_must_contain_lowercase",
      })
      .refine((value) => /[A-Z]/.test(value), {
        message: "password_must_contain_uppercase",
      })
      .refine((value) => /[0-9]/.test(value), {
        message: "password_must_contain_number",
      })
      .refine((value) => /[\W_]/.test(value), {
        message: "password_must_contain_special_character",
      }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwords_must_match",
    path: ["confirmPassword"],
  });
export type NewPasswordSchemaType = z.infer<typeof newPasswordSchema>;

// Schema for SIGN UP form
export const signUpSchema = z
  .object({
    email: z.string().email(),
    firstName: z.string().min(1, "this_field_is_required"),
    lastName: z.string().min(1, "this_field_is_required"),
    phoneNumber: z
      .string()
      .regex(/^\+\d*$/, "phone_number_must_be_valid")
      .min(10, "phone_number_is_too_short"),
    password: z
      .string()
      .min(8, "password_must_be_at_least_8_characters")
      .refine((value) => /[a-z]/.test(value), {
        message: "password_must_contain_lowercase",
      })
      .refine((value) => /[A-Z]/.test(value), {
        message: "password_must_contain_uppercase",
      })
      .refine((value) => /[0-9]/.test(value), {
        message: "password_must_contain_number",
      })
      .refine((value) => /[\W_]/.test(value), {
        message: "password_must_contain_special_character",
      }),
    confirmPassword: z.string(),
    terms: z.boolean(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwords_must_match",
    path: ["confirmPassword"],
  });

export type SignUpSchemaType = z.infer<typeof signUpSchema>;

// Schema for ORGANISATION DETAILS form
export const orgDetailsSchema = z.object({
  name: z.string().min(1, "company_name_is_required"),
  businessId: z.string().min(1),
  country: z.enum(countries).default(defaultCountry),
  city: z.string().min(1, "city_is_required"),
  streetAddress: z.string().min(1, "this_field_is_required"),
  postalCode: z.string().min(5, "min_5_digits"),
  timezone: z.enum(timezones).default(defaultTimezone),
  documentLocale: z.enum(documentLocales).default(defaultDocumentLocale),
});

export type OrgDetailsSchemaType = z.infer<typeof orgDetailsSchema>;

// Schema for BILLING INFORMATION form
export const billingInfoSchema = z
  .object({
    isAnotherCompany: z.boolean(),
    billingBusinessName: z.string().nullable().optional(),
    billingBusinessId: z.string().nullable().optional(),
    billingEmail: z.string().email(),
    billingEinvoicingAddress: z.string().min(1),
    billingEinvoicingOperator: z.string().min(1),
    billingAdditionalInformation: z.string().optional(),
    billingIsVatExempt: z.boolean(),
    billingAddressLine: z.string().optional(),
    billingCity: z.string().optional(),
    billingPostalCode: z.string().optional(),
    billingCountry: z.enum(countries).optional(),
    billingBankAccountNumber: z
      .string()
      .refine((iban) => isValid(iban), { message: "invalid_iban" })
      .optional(),
    billingBic: z
      .string()
      .max(11, "bic_must_be_at_most_11_characters")
      .optional(),
    billingCurrency: z.enum(currencies).default(defaultCurrency),
    isAnotherPostalAddress: z.boolean(),
    netPaymentTerm: z.number().optional(),
    billingContactPersonName: z.string().optional(),
    billingBuyerReference: z.string().optional(),
    billingOrderIdentifier: z.string().optional(),
  })
  // .refine(
  //   (data) => {
  //     return !data.isAnotherCompany || !!data.billingBusinessName;
  //   },
  //   {
  //     message: "this_field_is_required",
  //     path: ["billingBusinessName"],
  //   },
  // )
  .refine(
    (data) => {
      return !data.isAnotherCompany || !!data.billingBusinessId;
    },
    {
      message: "this_field_is_required",
      path: ["billingBusinessId"],
    },
  )
  .refine(
    (data) => {
      return !data.isAnotherPostalAddress || !!data.billingAddressLine;
    },
    {
      message: "this_field_is_required",
      path: ["billingAddressLine"],
    },
  )
  .refine(
    (data) => {
      return !data.isAnotherPostalAddress || !!data.billingCity;
    },
    {
      message: "this_field_is_required",
      path: ["billingCity"],
    },
  )
  .refine(
    (data) => {
      return !data.isAnotherPostalAddress || !!data.billingCountry;
    },
    {
      message: "this_field_is_required",
      path: ["billingCountry"],
    },
  )
  .refine(
    (data) => {
      return !data.isAnotherPostalAddress || !!data.billingPostalCode;
    },
    {
      message: "this_field_is_required",
      path: ["billingPostalCode"],
    },
  );

export type BillingInfoSchemaType = z.infer<typeof billingInfoSchema>;

export const EditBillingSchema = z
  .object({
    additionalInformation: z.string().nullable().optional().default(null),
    streetAddress: z.string().min(1),
    businessId: z.string().min(1),
    businessName: z.string().min(1),
    city: z.string().min(1),
    country: z.enum(countries),
    einvoicingAddress: z.string().optional().nullable().default(null),
    einvoicingOperator: z.string().optional().nullable().default(null),
    invoicingDeliveryType: z.enum(invoicingTypes),
    emailAddress: z.string().email(),
    isVatExempt: z.boolean(),
    postalCode: z.string().min(5),
    currency: z.enum(currencies).default(defaultCurrency),
    netPaymentTerm: z.number().optional(),
    documentLocale: z.enum(documentLocales),
    contactPersonName: z.string().nullable().optional().default(null),
    buyerReference: z.string().nullable().optional().default(null),
    orderIdentifier: z.string().nullable().optional().default(null),
  })
  .refine(
    (data) => {
      return (
        data.invoicingDeliveryType !== "einvoice" || !!data.einvoicingAddress
      );
    },
    {
      message: "this_field_is_required",
      path: ["einvoicingAddress"],
    },
  )
  .refine(
    (data) => {
      return (
        data.invoicingDeliveryType !== "einvoice" || !!data.einvoicingOperator
      );
    },
    {
      message: "this_field_is_required",
      path: ["einvoicingOperator"],
    },
  );

export type EditBillingInfoType = z.infer<typeof EditBillingSchema>;

// Schema for SITE CHANGING form
export const siteChangeSchema = z.object({
  name: z.string().min(1, "this_field_is_required"),
  streetAddress: z.string().min(1, "this_field_is_required"),
  city: z.string().min(1, "this_field_is_required"),
  postalCode: z.string().min(1, "this_field_is_required"),
  country: z.enum(countries),
  latitude: z.number().min(-90).max(90),
  longitude: z.number().min(-180).max(180),
  public: z.boolean(),
  freeHeating: z.boolean(),
});

export const EditPayoutsSchema = z
  .object({
    businessName: z.string().nullable().optional(),
    businessId: z.string().nullable().optional(),
    streetAddress: z.string(),
    postalCode: z.string(),
    city: z.string(),
    country: z.enum(countries),
    bankAccountNumber: z
      .string()
      .refine((iban) => isValid(iban), { message: "invalid_iban" }),
    bic: z.string().max(11, "bic_must_be_at_most_11_characters"),
    emailAddress: z.string(),
    documentLocale: z.enum(documentLocales),
    personFirstName: z.string().nullable().optional(),
    personLastName: z.string().nullable().optional(),
    payoutType: z.enum(["business", "person"]),
  })
  .refine(
    (data) => {
      return data.payoutType !== "business" || !!data.businessName;
    },
    {
      message: "this_field_is_required",
      path: ["businessName"],
    },
  )
  .refine(
    (data) => {
      return data.payoutType !== "business" || !!data.businessId;
    },
    {
      message: "this_field_is_required",
      path: ["businessId"],
    },
  )
  .refine(
    (data) => {
      return data.payoutType !== "person" || !!data.personFirstName;
    },
    {
      message: "this_field_is_required",
      path: ["personFirstName"],
    },
  )
  .refine(
    (data) => {
      return data.payoutType !== "person" || !!data.personLastName;
    },
    {
      message: "this_field_is_required",
      path: ["personLastName"],
    },
  );
export type EditPayoutsInfoType = z.infer<typeof EditPayoutsSchema>;

export enum Status {
  NotInitialized = "",
  InvitationNotFound = "Invitation not found",
  SomethingWentWrong = "Something went wrong",
  Authorized = "Authorized user",
  Unauthorized = "Unauthorized user",
}

export type Charger = components["schemas"]["chargerReply"];
