export enum TARIFF_TYPES {
  ENERGY = "energy-fixed",
  SPOT = "spot-standard",
}

export const locales = ["en", "fi"];
export type Locale = (typeof locales)[number];
export const defaultLocale: Locale = "fi";
export const localeNames: Record<Locale, string> = {
  en: "English",
  fi: "Suomi",
};
export const authLocaleNames: Record<Locale, string> = {
  en: "EN",
  fi: "FI",
};

// COUNTRIES
export const countries = ["FIN"] as const;
export type Countries = (typeof countries)[number];
export const defaultCountry: Countries = "FIN";
export const CountryNames: Record<Countries, string> = {
  FIN: "Finland",
};

// CURRENCIES
export const currencies = ["EUR"] as const;
export type Currency = (typeof currencies)[number];
export const defaultCurrency: Currency = "EUR";
export const CurrencyNames: Record<Currency, string> = {
  EUR: "EUR",
};

// TIMEZONES
export const timezones = ["Europe/Helsinki"] as const;
export type Timezone = (typeof timezones)[number];
export const defaultTimezone: Timezone = "Europe/Helsinki";
export const TimezoneNames: Record<Timezone, string> = {
  "Europe/Helsinki": "Europe/Helsinki",
};

export enum Roles {
  OWNER = "org:owner",
  ADMIN = "org:admin",
  MANAGER = "org:manager",
  MEMBER = "org:member",
}

export type Role = keyof typeof Roles;

export const documentLocales = ["en", "fi"] as const;
export type DocumentLocale = (typeof documentLocales)[number];
export const defaultDocumentLocale: DocumentLocale = "fi";
export const DocumentLocaleNames: Record<DocumentLocale, string> = {
  en: "English",
  fi: "Suomi",
};

export const invoicingTypes = ["email", "einvoice"] as const;

export const defaultNetPaymentTerm = 14;

export const plugTypes = ["CCS", "CHAdeMO", "schuko", "type2"] as const;
export type SocketPlugType = (typeof plugTypes)[number];

export const defaultSocketType = "type2";
